

.image-caption {
	background-color: rgba(185, 42, 38, 0.5);
	box-shadow: inset 0 4px 40px rgba(0, 0, 0, 0.3); }


.image-caption h3,
.image-caption h5 {
	color: #fff;
	font-family: 'Nunito Sans', sans-serif; }

.grid-loadMore {
	border-radius: 0;
	padding: 0.5rem 1.2rem;
	font-size: 1.0625rem;
	line-height: 27px;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	background-color: $primary;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25);
	@include trans;
	color: #fff;
	&:active,
	&:focus,
	&:visited,
	&:hover {
		color: #fff;
		border-color: $secondary;
		box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.55); } }

.grid-loadMore::before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $secondary;
	border-color: $secondary;
	color: #fff !important;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out; }

.grid-loadMore:hover::before,
.grid-loadMore:focus::before,
.grid-loadMore:active::before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	color: #fff !important;
	border-color: $secondary !important; }

.category-navbar li.select a {
	background-color: $primary;
	&:hover {
		color: #fff; } }


@media (max-width: 767px) {
	.category-navbar {
		display: none !important; } }




