
.modal-content {
	border-radius: 0; }

.modal-header {
	padding: 5px 15px;
	border-bottom: none; }

.modal-title {
	font-size: 25px; }



@media (min-width: 576px) {
	.modal-dialog {
		max-width: 700px; } }


