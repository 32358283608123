
.section-onze {
	background-color: #fff;
	background-image: url(../img/onze-bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 20px;
	&-border {
		border: 15px solid rgba(255,255,255,0.5);
		padding: 30px 0;
		text-align: center; } }



.onze-card-link {
	display: inline-block;
	width: 100%;
	max-width: 255px;
	min-height: 250px;
	margin: 15px;
	position: relative;
	// +trans-03
	&:hover {
		text-decoration: none; }
	&:hover .onze-card {
		transform: rotateY(180deg);
		z-index: 1;
		opacity: 0; }
	&:hover .onze-card-content {
		transform: rotateY(0deg);
		opacity: 1;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.4); } }

.onze-card {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: inline-block;
	width: 100%;
	height: 100%;
	// min-width: 200px
	background-color: rgba(255,255,255,0.9);
	padding: 35px;
	text-align: center;
	@include trans;
	img {
		display: inline-block;
		width: 100%;
		max-width: 120px;
		margin-bottom: 20px; }
	&-text {
		font-family: 'Nunito Sans', sans-serif;
		margin-bottom: 8px; }
	// &:hover
	// 	background-color: #fff
	// 	box-shadow: 0px 0px 10px rgba(0,0,0,0.4)
 }	// 	text-decoration: none

.onze-card-content {
	transform: rotateY(180deg);
	background-color: #fff;
	z-index: 2;
	padding: 35px 5px;
	opacity: 0;
	img {
		max-width: 60px; } }




@media screen and (max-width: 767px) {
	.section-onze {
		padding: 10px; }

	.section-onze-border {
		border: 10px solid rgba(255, 255, 255, 0.5);
		padding-bottom: 15px; }

	.onze-card-link {
		max-width: 220px;
		margin: 10px; }

	.onze-card {
		max-width: 220px;
		br {
			display: none; } } }




@media screen and (max-width: 580px) {
	.onze-card {
		max-width: 180px;
		// padding: 20px
 }		// margin: 15px 10px

	.onze-card-link {
		max-width: 180px;
		min-height: 230px; } }




@media screen and (max-width: 475px) {

	.section-onze {
		padding: 0; }

	.section-onze-border {
		border: 0; }

	.onze-card-link {
		margin: 10px 5px; } }





@media screen and (max-width: 415px) {
	.onze-card-link {
		max-width: 165px;
		min-height: 220px; }

	.onze-card {
		padding: 15px; }

	.onze-card-content {
		.onze-card-text,
		.btn {
			@include rem(15); } } }





@media screen and (max-width: 390px) {
	.onze-card-link {
		max-width: 155px; }

	.onze-card-content img {
		max-width: 50px;
		margin-bottom: 10px; } }






@media screen and (max-width: 374px) {
	.onze-card-link {
		min-height: 190px;
		max-width: 130px; }

	.onze-card-content {
		img {
			display: none; }
		.btn {
			white-space: normal; } }

	.onze-card-text {
		@include rem(16); } }




