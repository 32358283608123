.section-home {
	padding: 0;
	background-image: url(../img/home-bg.jpg);
	background-position: left -100px;
	background-size: cover;
	background-repeat: no-repeat; }


.home-blind {
	position: relative;
	z-index: 4;
	background-color: rgba(0, 0, 0, 0.35);
	padding: 100px 0 80px; }



.home-text-container {}


.home-text {
	text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5); }

.home-title {
	color: #fff;
	margin-bottom: 0px;
	line-height: 124px; }

.home-subtitle {
	color: #fff;
	margin-bottom: 65px;
	letter-spacing: 1px; }




.home-call-action-box {
	bottom: -130px; }


.mouse {
	display: inline-block;
	width: 35px;
	height: 60px;
	border: 2px solid #fff;
	border-radius: 60px;
	position: relative;
	@include trans;
	&::before {
		@include trans;
		content: "";
		width: 8px;
		height: 8px;
		position: absolute;
		top: 10px;
		background-color: #fff;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 50%;
		opacity: 1;
		animation: mouse 2s infinite; }
	&:hover {
		border: 2px solid $primary;
		&::before {
			background-color: $primary; } } }

@keyframes mouse {
	from {
		opacity: 1;
		top: 10px; }
	to {
		opacity: 0;
		top: 40px; } }














@media screen and (max-width: 1250px) {
	.section-home {
		background-position: center; } }





@media screen and (max-width: 1199px) {

	.home-text {
		position: static; } }





@media screen and (max-width: 767px) {

	.home-title {
		line-height: inherit; }

	.home-blind {
		padding: 80px 0 20px; }

	.home-call-action-box {
		bottom: -70px; } }


