.custom-list {
	display: block;
	padding: 0;
	margin: 0;
	li {
		position: relative;
		color: #000;
		padding-left: 25px;
		margin-bottom: 30px;
		@include rem(18);
		list-style: none; }
	.fa {
		color: $primary;
		position: absolute;
		left: 0;
		top: 5px; }

	&.sm-margin-li {
		li {
			margin-bottom: 15px; } } }




// @media screen and (max-width: 991px)








