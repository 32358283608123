.section {
	padding: 70px 0;
	&.bigger-padding {
		padding: 100px 0; } }

.section-title {}

.section-sub-title {

	@include max767 {
		margin-bottom: 17px; } }





@media (max-width: 991px) {
	.section {
		&.bigger-padding {
			padding: 50px 0; } } }











