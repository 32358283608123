

// .footer
// .footer
// 	padding: 30px 0px 0px
// 	background-color: #001C46
// 	color: #fff
// 	border-top: 1px solid #394057



.copyright {
	margin-bottom: 30px;
	font-weight: 300;
	// text-align: right
	@include rem(13);
	position: relative;
	top: 3px;
	color: #c8cddfa6;
	a {
		display: inline-block;
		color: #c8cddfa6;
		font-weight: 300;
		@include trans;
		&:hover {
			color: #fff; } } }











