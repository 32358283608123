
body {
	background-color: $body-bg; }



.color-primary,
.color--primary {
	color: $primary; }


.color-secondary {
	color: $secondary; }

.color-success {
	color: $success; }

.color-warning {
	color: $warning; }

// .color-alert
// 	color: $alert


// .color--gray
// 	color: $text-gray

// .mb-0
// 	margin-bottom: 0

.h-100 {
	height: 100%; }











