.contact-form {
	margin: 0 auto;
	max-width: 280px;

	// &-title
	// 	margin-bottom: 30px
	// 	color: #fff
	// &-subtitle
	// 	margin-bottom: 40px
	// 	font-weight: 300
	// 	color: #fff

	.btn {
		padding: 10px 25px;
		@include rem(16); } }





form.styleguide {
	max-width: 600px;
	margin-bottom: 50px; }


.form-control {
	@include rem(14);
	color: #999999;
	border-color: #999999;
	border-radius: 0px;
	background-color: transparent;
	border-radius: 5px;
	&:focus {
		background-color: transparent;
		color: #999999; } }

label {
	display: inline-block;
	vertical-align: top;
	margin-right: 15px;
	[type='file'],
	[type='checkbox'],
	[type='radio'] {
		margin-bottom: 0; } }

input,
textarea,
select,
label,
[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
	@include fcn;
	@include rem(14);
	min-height: 40px; }


.address-form select.form-control:not([size]):not([multiple]) {
	height: calc(2.25rem + 19px); }



label {
	min-height: auto; }


textarea {
	height: 120px;
	max-height: 300px; }


.checkbox-group {
	margin-bottom: 10px;

	[type="checkbox"],
	label {
		margin-bottom: 0px; } }


.form-error {
	@include fcn; }


.form-control::-webkit-input-placeholder {
	color: #999999; }

.form-control:-moz-placeholder {
	color: #999999; }

.form-control::-moz-placeholder {
	color: #999999; }

.form-control:-ms-input-placeholder {
	color: #999999; }

.invalid-feedback {
	text-align: left; }





select:not([multiple]) {
	-webkit-appearance: none;
	-moz-appearance: none;
	background-position: 98% 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	background-image: url(../img/tpl/form-select-arrow.svg); }






.success-form {
	color: #fff !important;
	text-align: center;
	img {
		padding-top: 20px;
		display: block;
		margin: 0 auto;
		width: 100%;
		max-width: 230px; } }




.form-group {
	margin-bottom: 20px; }










// @media (max-width: 767px)


