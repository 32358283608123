
.section-contact-info {
	padding: 20px;
	background-color: #9e7e67;
	background-image: url(../img/home-bg.jpg);
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	text-shadow: 0px 0px 1px #000; }

#contact {
	border: 15px solid rgba(255, 255, 255, 0.5); }

.contact-content {
	background-color: rgba(0,0,0,0.5);
	padding: 60px 0 0; }

.contact-list {
	padding: 0;
	margin: 0;
	margin-bottom: 50px;
	@include rem(13);
	font-weight: 300;
	li {
		list-style: none;
		color: $light;
		margin-bottom: 17px;
		&:last-child {
			margin-bottom: 0; } }
	a,
	.address {
		display: inline-block;
		color: $light;
		text-decoration: none;
		@include trans;
		padding-left: 30px;
		position: relative;
		line-height: 13px;
		&:hover .fa {
			transform: scale(1.3);
			color: #fff; }
		&:focus,
		&:hover {
			color: #fff;
			text-decoration: underline; } }
	.address {
		&:hover {
			text-decoration: none; } }

	.fa {
		@include trans;
		@include rem(20);
		position: absolute;
		left: 0;
		top: 0;
		color: #fff;
		line-height: inherit; }
	.fa-envelope {
		@include rem(18); }
	.fa-file-text-o {
		top: 5px; } }



.footer-bottom {
	color: $light;
	border-top: 1px solid $light;
	padding-top: 20px;
	a {
		color: $light;
		display: inline-block;
		margin-bottom: 30px;
		@include trans;
		&:hover {
			color: #fff; } }
	.fa {
		@include rem(30); } }







.footer-logo {
	display: inline-block;
	margin-bottom: 25px;
	img {
		width: 100%;
		max-width: 282px; }
	&.marco img {
		max-width: 150px; } }



.social-list {
	padding: 0;
	margin: 0;
	margin-bottom: 30px;
	li {
		list-style: none;
		display: inline-block; }
	a {
		padding: 5px;
		display: inline-block;
		@include rem(26);
		color: #fff;
		&:hover {
			color: $primary; }
		&:hover .fa {
			transform: scale(1.1); } }
	.fa {
		@include trans; } }




@media screen and (max-width: 767px) {
	#contact {
		border: 10px solid rgba(255, 255, 255, 0.5); }

	.section-contact-info {
		padding: 10px; } }



@media screen and (max-width: 575px) {
	.footer-bottom {
		flex-direction: column !important; } }






