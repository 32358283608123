

// Colors

$primary: #b80013;
$secondary: #45a6d4;
// $secondary: #114996
// $success: #2ECC71
// $danger: #C63F4F
// $danger: #D82B40
// $warning: #F5A623
// $info: $cyan
// $light: #cac9c9
$light: rgba(255,255,255,0.6);
$dark: #000000;





// main text color
$main: #666666;
$body-color: #666666 !default;

$body-bg: #fff;


// Fonts
$font-size-base-custom: 16;
$font-size-base-mobile: 14;

// Font Families
$font-family-base: 'Nunito Sans', sans-serif;
$font-main: $font-family-base;
$font-secondary: $font-family-base;
$font-condensed: $font-family-base;

@mixin fm {
	font-family: $font-main; }

@mixin fs {
	font-family: $font-secondary; }

@mixin fcn {
	font-family: $font-condensed; }

@mixin fcn2 {
	font-family: $font-condensed; }



// headings
$h1-font-size-custom: 46;
$h2-font-size-custom: 36;
$h3-font-size-custom: 26;
$h4-font-size-custom: 18;
$h5-font-size-custom: 16;
$h6-font-size-custom: 14;



// Animations Time
$transition-time: 0.1s;


$btn-transition: background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out !default;

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1.2rem !default;


