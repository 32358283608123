

.btn {
	@include trans;
	border-radius: 0;
	@include rem(17);
	font-weight: 400;
	box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25);
	&.btn-full-width {
		width: 100%; } }

.btn:hover,
.btn:focus,
.btn:visited,
.btn:active {
	box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.55);
	outline: none; }

button:hover {
	cursor: pointer; }



.btn-primary:not([disabled]):not(.disabled):active {
	box-shadow: 8px 14px 40px 0px rgba(30, 10, 28, 0.42); }



.btn-primary-transitions {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	background-color: $primary;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	@include trans;
	color: #fff;
	&:active,
	&:focus,
	&:hover {
		color: #fff;
		border-color: $secondary; } }


.btn-primary-transitions::before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $secondary;
	border-color: $secondary;
	color: #fff !important;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out; }

.btn-primary-transitions:hover::before,
.btn-primary-transitions:focus::before,
.btn-primary-transitions:active::before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	color: #fff !important;
	border-color: $secondary !important; }





.btn-transparent {
	background-color: transparent !important;
	color: #fff;
	@include trans;
	border-color: #fff; }

.btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent:visited,
.btn-transparent:active {
	color: #fff;
	background-color: transparent !important; }

.btn-transparent:not([disabled]):not(.disabled):active {
	border-color: transparent; }

.btn-transparent-transitions::before {
	background-color: #fff;
	border-color: $secondary; }


.btn-icon .fa {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out; }

.btn-icon:hover .fa,
.btn-icon:focus .fa,
.btn-icon:active .fa {
	-webkit-animation-name: hvr-icon-pulse;
	animation-name: hvr-icon-pulse;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;


 } // Icon Pulse
@-webkit-keyframes hvr-icon-pulse {
	25% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3); }

	75% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8); } }


@keyframes hvr-icon-pulse {
	25% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3); }

	75% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8); } }


.hvr-icon-pulse {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	.hvr-icon {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out; }
	&:hover .hvr-icon, &:focus .hvr-icon, &:active .hvr-icon {
		-webkit-animation-name: hvr-icon-pulse;
		animation-name: hvr-icon-pulse;
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite; } }


.button.hollow {
	border-width: 2px; }

.button.hollow.white {
	border-color: rgba(#fff, 0.9);
	color: rgba(#fff, 0.9);

	@include hf {
		border-color: #fff;
		color: #fff; } }

.btn-arrow {
	.right-arrow {
		margin-right: 5px;
		position: relative;
		top: -2px; } }





.view-all {
	vertical-align: baseline;
	@include rem(12);
	text-decoration: underline;
	text-transform: none; }



// .btn-sorting
.btn-secondary-size {
	@include rem(10);
	text-transform: uppercase;
	border-radius: 40px;
	letter-spacing: 1px;
	padding: 7px 20px; }


.btn-collapse {
	width: 16px;
	height: 16px;
	display: inline-block;
	position: absolute;
	right: 0;
	top: 0;
	background-image: url("../img/tpl/collapse-btn-icon-minus.svg");
	&.collapsed {
		background-image: url("../img/tpl/collapse-btn-icon-plus.svg"); } }


.btn-group-circle {
	.btn {
		margin: 0 7px;
		border-radius: 50%;
		@include rem(14);
		padding: 5px 7px;
		min-width: 33px;
		&.active {
			box-shadow: none !important; } } }

.btn-group-circle > .btn:first-child:not(:last-child):not(.dropdown-toggle),
.btn-group-circle > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-circle > .btn:last-child:not(:first-child) {
	border-radius: 50%; }


.quantity-selector {
	margin-bottom: 20px;
	.btn {
		border-radius: 50px;
		@include rem(14);
		padding: 0.2rem 0.6rem; } }











