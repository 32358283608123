
html {
	font-size: $font-size-base + px; }

body {
	font-size: 1rem;
	background-color: $primary;
	color: #666666;
	&.no-scroll {
		margin: 0;
		height: 100%;
		overflow: hidden; } }

.fw-200 {
	font-weight: 200 !important; }

.fw-300 {
	font-weight: 300 !important; }

.fw-400 {
	font-weight: 400 !important; }

.fw-500 {
	font-weight: 500 !important; }

.fw-600 {
	font-weight: 600 !important; }

.fw-700 {
	font-weight: 700 !important; }

.fw-800 {
	font-weight: 800 !important; }

.fw-900 {
	font-weight: 900 !important; }


// Font family
.ff-nunito-sans {
	font-family: 'Nunito Sans', sans-serif; }
.ff-halant {
	font-family: 'Halant', serif; }


// Headers
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 1.25rem;
	font-family: 'Halant', serif;
	color: #000;
	// font-weight: 700

	a {
		color: inherit;
		@include hf {
			color: inherit; } }
	&.white-title {
		@include rem(150);
		color: rgba(255,255,255,1);
		line-height: 130px;
		// text-shadow: 0px 0px 0px #e9f0f3
		text-shadow: 2px   0  0   #e9f0f3, -2px   0  0   #e9f0f3, 0    2px 0   #e9f0f3, 0   -2px 0   #e9f0f3, 1px  1px 0   #e9f0f3, -1px -1px 0   #e9f0f3, 1px -1px 0   #e9f0f3, -1px  1px 0   #e9f0f3, 1px  1px 5px #e9f0f3; }
	&.grey-title {
		@include rem(150);
		color: #d4d7dc;
		line-height: 130px;
		text-shadow: 1px   0  0   #fefefd, -1px   0  0   #fefefd, 0    1px 0   #fefefd, 0   -1px 0   #fefefd, 1px  1px 0   #fefefd, -1px -1px 0   #fefefd, 1px -1px 0   #fefefd, -1px  1px 0   #fefefd, 1px  1px 5px #fefefd; } }
h1,
.h1 {
	// margin-bottom: 0.4em
	&.bigger {
		@include rem(100); } }

h2,
.h2 {
	// margin-bottom: 0.4em
	&.bigger {
		@include rem(48); }
	&.smaller {
		@include rem(30); } }

h3,
.h3 {
	// margin-bottom: 0.4em
	&.smaller {
		@include rem(24); } }

h4,
.h4 {
	// margin-bottom: 0.4em
	&.bigger {
		@include rem(20); } }

h5,
.h5 {
 }	// margin-bottom: 0.4em

h6,
.h6 {
	// margin-bottom: 0.4em
	&.smaller {
		@include rem(13); } }






::selection {
	background-color: #266a8f;
	color: #fff; }





.bg-white {
	background-color: #fff !important; }

.bg-grey {
	background-color: #f9fcfe !important; }





.table-parent-md {
	display: table;
	width: 100%;
	height: 100%; }

.vertical-middle {
	display: table-cell;
	vertical-align: middle; }


// hr
// 	border: none
// 	background-color: #3B3B3B
// 	width: 100%
// 	height: 1px
// 	margin-top: 0.5rem
// 	margin-bottom: 0.5rem



p,
address,
ol,
ul,
dl,
blockquote {
	margin-bottom: 1.25rem; }


address {
	font-style: normal; }


.accordion-title,
a.accordion-title {
	background-color: $primary;
	@include hf {
		color: #fff; } }

.callout {
	border: none;
	border-radius: 0;
	a {
		color: inherit; } }


.tabs {
	@include fs;

	a {
		@include fc {
			outline: 0; } } }

.badge,
.label {
	margin-bottom: 4px;

	&.success,
	&.primary,
	&.warning,
	&.alert {
		color: #fff; } }


hr {
	border: none;
	background-color: $primary;
	width: 100%;
	height: 1px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem; }



.subsection {
	margin-bottom: 40px; }

.position-relative {
	position: relative; }


.gotop-btn {
	position: fixed;
	right: 0px;
	bottom: 50px;
	z-index: -2;
	font-size: 30px;
	line-height: 40px;
	padding: 2px 12px;
	opacity: 0;
	border-radius: 50%;
	display: inline-block;
	@include trans;
	.fa {
		position: relative;
		top: -2px; }
	&.visible {
		right: 50px;
		opacity: 1;
		z-index: 995; } }










@media screen and (max-width: 1199px) {
	h2.bigger, .h2.bigger {
		@include rem(36); }

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		&.white-title {
			@include rem(125); }
		&.grey-title {
			@include rem(125); } }

	.gotop-btn.visible {
		right: 20px;
		bottom: 20px; } }


@media screen and (max-width: 991px) {

	h1.bigger, .h1.bigger {
		@include rem(46); }

	h1 {
		@include rem(32); }
	h2 {
		@include rem(28); }
	h3 {
		@include rem(24); }

	.home-title {
		line-height: 55px; }

	.navbar-brand img,
	.header_content .navbar-brand {
		max-width: 155px; }


	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		&.white-title {
			@include rem(100); }
		&.grey-title {
			@include rem(100); } } }




@media screen and (max-width: 767px) {
	.gotop-btn {
		bottom: 30px;
		padding: 0px 10px;
		&.visible {
			right: 30px;
			bottom: 30px; } }

	h1.bigger, .h1.bigger {
		@include rem(42); }

	h1 {
		@include rem(28); }

	h2.bigger, .h2.bigger {
		@include rem(26); }

	h2, h2.smaller, .h2.smaller {
		@include rem(23); }
	h3, h4.bigger, .h4.bigger {
		@include rem(18); }

	.navbar-brand img, .header_content .navbar-brand {
		max-width: 115px;
		min-width: 115px; }

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		&.white-title {
			@include rem(80);
			line-height: 80px; }
		&.grey-title {
			@include rem(80);
			line-height: 80px; } } }






@media screen and (max-width: 475px) {
	h2, h2.smaller, .h2.smaller {
		@include rem(20); }

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		&.white-title {
			@include rem(60);
			line-height: 60px; }
		&.grey-title {
			@include rem(60);
			line-height: 60px; } } }




@media (min-width: 1200px) {
	.container {
		max-width: 1280px;
 }		// max-width: 1170px

	.container.bigger {
		max-width: 1440px; } }

