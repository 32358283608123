


.header {
	box-shadow: 0px 0px 26.73px 0.27px rgba(29, 29, 29, 0.15);

	&_content {
		min-height: 121px;
		background-color: transparent;
		z-index: 6;
		position: relative;
		@include trans;
		box-shadow 0px 0px 26.73px 0.27px rgba(29, 29, 29, 0.15) {}
		&:hover {
			background-color: transparent; } } }


.mobile-nav-bg {
	background-color: rgba(0,0,0,0.6);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 998;
	display: none;
	transition: all .5s ease-in-out; }

.mobile-nav-bg.show {
	display: block; }

.sticky-logo {
	display: none; }

.btn-link-sticky {
	margin-left: 10px;
	display: none; }

.sticky-phone {
	display: none; }




.secondary-nav {
	background-color: #001C46;

	.navbar-nav {
		.nav-link {
			color: #fff;
			font-weight: 400;
			@include rem(14);
			padding-right: 30px;
			padding-left: 30px;
			&:hover {
				text-decoration: none;
				color: $primary; } } } }



.custom-nav {
	padding-top: 3px;
	padding-bottom: 0;
	min-height: 60px;
	// position: fixed
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
	background-color: #fff;
	@include trans;

	.btn {
		@include rem(16); }

	.navbar-toggler-icon {
		background-image: none;
		width: 33px;
		height: 22px;
		position: relative;
		margin: 3px 0 5px 5px;
		.top-line,
		.middle-line,
		.bottom-line {
			display: inline-block;
			background-color: #000;
			height: 2px;
			border-radius: 20px;
			position: absolute;
			left: 0;
			right: 0;
			@include trans; }
		.top-line {
			top: 0; }
		.middle-line {
			top: 50%;
			margin-top: -1px; }
		.bottom-line {
			bottom: 0; } }

	.navbar-toggler.active {
		color: #fff;
		.navbar-toggler-icon {
			.top-line {
				top: 50%;
				margin-top: -1px;
				transform: rotate(-45deg);
				background-color: #fff; }
			.middle-line {
				opacity: 0;
				background-color: #fff; }
			.bottom-line {
				bottom: auto;
				top: 50%;
				margin-top: -1px;
				transform: rotate(45deg);
				background-color: #fff; } } }


	.primary-nav {}
	.navbar-nav {

		.nav-link {
			@include rem(16);
			color: #666666;
			font-weight: 600;
			padding: 0;
			white-space: nowrap;
			border: none;
			text-transform: uppercase;
			position: relative;
			&::before {
				width: 100%;
				height: 2px;
				background-color: #45a6d4;
				content: "";
				display: inline-block;
				position: absolute;
				bottom: 10px;
				left: 0;
				opacity: 0;
				@include trans; } } }

	.nav-item {
		padding: 15px 0px 10px 0px;
		margin-right: 1rem;
		margin-left: 1rem;
		// &:last-child
		// 	padding: 0
 }		// 	padding-top: 14px

	.secondary-nav {
		background-color: transparent;
		.nav-link {
			@include rem(12); } } }


.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	&::before {
		opacity: 1;
		bottom: -3px; } }

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
	&::before {
		opacity: 1;
		bottom: -3px; } }

.navbar-brand {
	padding: 0;
	margin-right: 15px;
	font-size: 18px;
	img {
		width: 100%;
		max-width: 210px; } }

.nav-name {
	text-transform: uppercase; }

.navbar-toggler {
	font-size: 16px; }

.navbar-toggler-icon {
	margin-top: -6px; }

.nav-phone {
	padding: 7px 10px;
	font-size: 15px; }


.navbar-light {
	.nav-item-phone {
		margin-left: 1.5rem;
		.nav-phone {
			color: #000 !important;
			&:hover,
			&:focus {
				border-color: transparent; }
			&:hover .fa {
				color: $primary; } } } }



.header_content {
	.navbar-brand {
		min-width: 210px;
		min-height: 121px;
		img {
			position: absolute;
			left: 10px;
			top: 10px;
			box-shadow: 0px 0px 20px rgba(0,0,0,0.13); } } }

.header_content.smaller-menu {

	.navbar-brand {
		min-width: auto;
		min-height: auto;
		top: -8px;
		img {
			position: static;
			max-width: 150px;
			box-shadow: none; } }

	.secondary-nav {
		display: none; }

	.navbar-nav {
		padding: 0;
		background-color: transparent; }

	.custom-nav {
		position: fixed;
		min-height: 44px;
		top: 0;
		@include trans;
		box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);

		.nav-item {
			padding-top: 10px;
			// &:last-child
 }			// 	padding-top: 7px

		.nav-link {
			margin-left: 0;
			padding-left: 0; }
		.navbar-toggler {
			color: #000;
			font-size: 16px; }
		.navbar-toggler-icon {
			.top-line,
			.middle-line,
			.bottom-line {
				background-color: #000; } }
		.navbar-toggler.active {
			color: #fff;
			.top-line,
			.middle-line,
			.bottom-line {
				background-color: #fff; } } }

	.nav-item.bigger-screen-padding {
		padding-left: 0; }

	.header-logo {
		display: none; }

	.sticky-logo {
		display: inline-block; }

	.btn-link-sticky {
		display: inline-block;
		padding: 5px;
		.btn-light {
			font-size: 14px !important; }
		.nav-phone {
			font-size: 13px; } }

	.sticky-phone {
		display: inline-block;
		.nav-link {
			@include rem(18);
			padding-top: 5px;
			color: $primary;
			display: inline-block; } } }



.navbar-toggler {
	cursor: pointer;
	padding: 5px 10px; }


.dropdown-menu {
	border-radius: 0;
	top: 90%;
	box-shadow: 4px 8px 21px 0px rgba(0, 0, 0, 0.5); }

.dropdown-item:focus, .dropdown-item:hover {
	color: $primary; }

.dropdown-item:active {
	color: #fff; }

.dropdown-toggle::after {
	border: none;
	content: "\f107";
	font-family: FontAwesome;
	vertical-align: 0; }




.btn-phone-sm-screen {
	padding: 0 15px;
	margin-left: auto;
	display: none;
	@include rem(26); }












@media screen and (max-width: 1199px) {
	.btn-phone-sm-screen {
		display: inline-block; }

	.header_content {
		min-height: 73px; } }




@media (min-width: 1200px) {
	.header_content.smaller-menu {
		.custom-nav {
			.navbar-collapse {
				position: static; } } } }











@media screen and (max-width: 1199px) {

	.header {
		.navbar-nav {
			background-color: transparent; }

		.secondary-nav {
			display: none; } }

	.custom-nav {
		.dropdown-menu {
			background-color: transparent; }
		.dropdown-item {
			color: #fff;
			background-color: transparent;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0; }
			&:focus,
			&:hover {
				color: $primary; } }

		.navbar-toggler {
			z-index: 999;
			border: none;
			margin-left: 20px;
			color: #000; }

		.navbar-collapse {
			background-color: rgba(0,0,0,0.8);
			position: fixed;
			left: 0;
			top: 0;
			height: 100vh;
			width: 235px;
			padding-top: 10px;
			padding-bottom: 50px;
			padding-left: 15px;
			transition: all .5s ease-in-out;
			z-index: 1003;
			overflow-y: scroll; }

		.mobile-nav {
			left: -280px;
			opacity: 0;
			margin: 0; }

		.mobile-nav.show {
			left: 0;
			opacity: 1; }

		.navbar-nav {
			.nav-item {
				margin-bottom: 10px; }
			.nav-link {
				color: #fff;
				margin: 0;
				padding-left: 0;
				display: inline-block; } } }



	.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
		color: #fff;
		&::before {
			opacity: 1;
			bottom: -3px; } }

	.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
		color: #fff;
		&::before {
			opacity: 1;
			bottom: -3px; } }

	.sticky-phone {
		display: inline-block; } }



@media screen and (max-width: 991px) {

	.header_content.smaller-menu {
		.navbar-brand img {
			max-width: 130px; } }


	.custom-nav {
		.navbar-toggler-icon {
			width: 27px;
			height: 20px; } } }






@media screen and (max-width: 767px) {
	.custom-nav {
		padding: 0 5px;

		.navbar-toggler {
			position: relative;
			top: 5px; } }

	.header_content .navbar-brand {
		min-height: 78px; } }





@media screen and (max-width: 480px) {
	.header_content {
		min-height: 50px; }
	.navbar-brand img {
		max-width: 155px; }

	.navbar-brand {
		top: -8px; }

	.custom-nav {
		padding: 0;
		// min-height: 50px
		.navbar-toggler {
			padding-right: 3px;
			padding-top: 10px;
			top: 0; } } }







@media (max-width: 390px) {
	.header_content.smaller-menu .btn-link-sticky .nav-phone {
		display: none; } }



@media (max-width: 374px) {
	.btn-phone-sm-screen {
		@include rem(20);
		margin-left: 0px;
		padding: 0 7px; } }
























