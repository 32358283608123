
// custom variables and mixins
@import "variables";
@import "mixins";

// foundation
@import "bootstrap/scss/bootstrap.scss";

// font awesome
@import "font-awesome/font-awesome.scss";

// custom global
@import "global";

// custom components
@import "components/buttons.sass";
@import "components/footer.sass";
@import "components/forms.sass";
@import "components/grid-gallery.sass";
@import "components/header.sass";
@import "components/image.sass";
@import "components/layout.sass";
@import "components/list.sass";
@import "components/modal.sass";
@import "components/owl-carousel.sass";
@import "components/section-call-to-action.sass";
@import "components/section-contact-info.sass";
@import "components/section-home.sass";
@import "components/section-onze.sass";
@import "components/section-over-ons.sass";
@import "components/typography.sass";


