.photo-frame {
	// &.over-ons
	// 	background-image: url(../img/over-ons.jpg)
	// 	filter: blur(8px)
	// 	padding: 15px
	// 	position: relative
	// 	z-index: 1
	img {
		// position: relative
		// z-index: 2
		// filter: blur(1px) !important
		width: 100%;
		max-width: 100%; } }
