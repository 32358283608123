

.product-carousel {
	padding-right: 50px; }

.product-carousel-container {
	position: relative; }


.owl-nav {
	display: none; }

.owl-theme .owl-dots {
	margin-bottom: 20px;
	.owl-dot span {
		background-color: $secondary; } }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
	background-color: $primary; }




.review-owl-btn {
	padding: 0 5px;
	color: $primary;
	@include rem(36);
	position: absolute;
	top: 50%;
	margin-top: -48px;
	display: none;
	z-index: 10;
	&:hover {
		color: $secondary; }
	&.next {
		right: -14px; }
	&.prev {
		left: -14px; } }




.service-carousel .owl-dots {
	display: none; }





@media screen and (max-width: 991px) {

	.review-owl-btn {
		display: inline-block; } }






