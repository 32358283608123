

.call-action-box {
	width: 100%;
	max-width: 930px;
	padding: 40px;
	background-color: #fff;
	background-image: url(../img/home-bg.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	margin: 0 auto;
	@include trans;
	box-shadow: inset 0px 0px 20px 0px rgba(0,0,0,1);
	position: relative;
	// border-bottom: 6px solid $secondary
	// opacity: 0.8
	// margin-bottom: -100px
	// color: #002255
	&:hover {
		// max-width: 980px
		// padding: 50px
		.call-action-content {
			background-color: $primary; }
 }		// opacity: 1
	&.bigger {
		max-width: 1750px;
		background-position: center top;
		background-attachment: fixed; } }

.call-action-content {
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,1);
	background-color: rgba(184, 0, 19, 0.75);
	@include trans;
	padding: 65px 55px;
	color: #fff; }

.call-action-title {
	margin-bottom: 0px; }



.small-banner {
	margin: 0 auto;
	max-width: 1820px;
	position: relative;
	&-title {
		margin-bottom: 0;
		padding: 14px 0;
		color: #fff;
		display: inline-block;
		text-align: left; }
	&-text {
		border-left: 2px solid #fff;
		margin-bottom: 0;
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 30px;
		display: inline-block;
		max-width: 600px;
		@include rem(18);
		color: #fff; } }











@media (max-width: 1199px) {
	.call-action-box {
		br {
			display: none; } } }


@media (max-width: 991px) {
	.small-banner-title {
		padding: 0;
		padding-bottom: 20px;
		border: none;
		border-bottom: 2px solid #fff;
		display: block; }

	.small-banner-text {
		padding: 0;
		padding-top: 20px;
		border: none; }

	.call-action-content {
		padding: 55px 35px; } }




@media (max-width: 767px) {
	.small-banner {
		padding: 0;
		margin-bottom: 30px; }

	.section-small-banner {
		padding: 0; }

	.call-action-content {
		display: inline-block !important;
		background-color: rgba(184, 0, 19, 1); }

	.call-action-title {
		margin-bottom: 30px; }

	.call-action-box {
		padding: 30px 15px; } }





@media (max-width: 425px) {
	.call-action-content {
		padding: 30px 20px; }

	.call-action-box {
		padding: 15px 15px; } }




